import _ from 'lodash'
import axios from '../common/axios.js'

export default {
	data() {
		return {
			
		}
	},
	created(){
		
	},
	methods: {
		hasFavoris(famille){
			let favoris = this.$store.state.user.favoris
			return typeof favoris[famille.id] != 'undefined'
		},
		getTotalFavoris(famille){
			let favoris = this.$store.state.user.favoris
			return Object.keys(favoris[famille.id]).length
		},
		getFavoris(famille){
			let favoris = this.$store.state.user.favoris
			return favoris[famille.id]
		},
		getCompanyName(favoris){
			return favoris.nom
		},
		getCompanyNameTruncat(favoris){
			return _.truncate(favoris.nom, {length: 50})
		},
		getNace(favoris){
			return typeof this.$store.state.ui.code_naces[favoris.nace_id] != 'undefined' ? this.$store.state.ui.code_naces[favoris.nace_id]['titre'] : ''
		},
		getNaceTruncat(favoris){
			return typeof this.$store.state.ui.code_naces[favoris.nace_id] != 'undefined' ? _.truncate(this.$store.state.ui.code_naces[favoris.nace_id]['titre'], {length: 40}) : ''
		},
		deleteFavoris(entreprise_id) {
			let self = this;

			this.$dialog.confirm(self.$t('filter_fav_confirm'), { loader: true })
				.then(dialog => {
					self.$store.dispatch('deleteFavori', entreprise_id)
					dialog.close();

				});
		}
	},
	computed:{
	},
	components: {
		SousMenu: () => import('./sous_menu.vue')
	}
}